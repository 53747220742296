import XLSX from 'xlsx'

/** DATA STATE **/
var state = {
  tickets: [{}],
  headers: []
}

/** HELPERS **/
function get_header_row(sheet) {
  var headers = [],
    range = XLSX.utils.decode_range(sheet['!ref'])
  var C,
    R = range.s.r /* start in the first row */
  for (C = range.s.c; C <= range.e.c; ++C) {
    /* walk every column in the range */
    var cell =
      sheet[
        XLSX.utils.encode_cell({
          c: C,
          r: R
        })
      ] /* find the cell in the first row */
    var hdr = 'UNKNOWN ' + C // <-- replace with your desired default
    if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
    headers.push(hdr)
  }
  return headers
}

function fixdata(data) {
  var o = '',
    l = 0,
    w = 10240
  for (; l < data.byteLength / w; ++l)
    o += String.fromCharCode.apply(
      null,
      new Uint8Array(data.slice(l * w, l * w + w))
    )
  o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))
  return o
}

/** PARSING and DRAGDROP **/
async function handleDrop(e) {
  e.stopPropagation()
  e.preventDefault()
  var files = e.dataTransfer.files,
    i,
    f
  let result_base64 = await new Promise(resolve => {
    for (i = 0, f = files[i]; i != files.length; ++i) {
      var reader = new FileReader()
      // var name = f.name;
      reader.onload = function(e) {
        var results,
          data = e.target.result,
          fixedData = fixdata(data),
          workbook = XLSX.read(btoa(fixedData), {
            type: 'base64'
          }),
          firstSheetName = workbook.SheetNames[0],
          worksheet = workbook.Sheets[firstSheetName]
        state.headers = get_header_row(worksheet)
        results = XLSX.utils.sheet_to_json(worksheet)
        state.tickets = results
        resolve(state)
      }
      reader.readAsArrayBuffer(f)
    }
  })
  return result_base64
}

function handleDragover(e) {
  e.stopPropagation()
  e.preventDefault()
  e.dataTransfer.dropEffect = 'copy'
}

export default {
  handleDrop,
  handleDragover,
  state
}
