<template>
  <section id="audience">
    <vx-card
      :title="
        lang.surveyIntegrations.channels[type].settings.audience.title[
          languageSelected
        ]
      "
      class="overflow-hidden mb-2"
      :subtitle="
        lang.surveyIntegrations.channels[type].settings.audience.subtitle[
          languageSelected
        ]
      "
    >
      <vs-table
        class="mt-0"
        max-items="10"
        :data="audience"
        pagination
        search
        :no-data-text="lang.general.noDataText[languageSelected]"
      >
        <template slot="thead">
          <vs-th
            v-for="(key, aKey) in audienceKeys"
            :key="aKey"
            :sort-key="key"
          >
            {{ key }}
          </vs-th>
          <vs-th v-if="audienceKeys.length">
            <a @click="removeAll" class="cursor-pointer remove-all">
              <feather-icon icon="XIcon" svgClasses="h-3 w-3 mr-2" />
              <span>
                {{
                  lang.surveyIntegrations.channels[type].settings.audience
                    .removeAll[languageSelected]
                }}
              </span>
            </a>
          </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td
              v-for="(akey, aKey) in audienceKeys"
              :key="aKey"
              :data="data[indextr][akey]"
              >{{ data[indextr][akey] }}</vs-td
            >
            <vs-td>
              <feather-icon
                icon="XIcon"
                class="cursor-pointer float-right text-primary"
                svgClasses="h-3 w-3 mt-2 mr-2"
                @click="removeAudience(indextr)"
              />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <TimeZoneSelector v-model="timezone" :showLabel="true" class="mt-4" />

      <div class="mt-4">
        <label for="expiration" class="vs-select--label">
          {{
            lang.surveyIntegrations.channels.whatsapp.settings.audience.form
              .expiration[languageSelected]
          }}
        </label>
        <date-picker
          id="expiration"
          class="inputx w-full"
          v-model="expiration"
          type="datetime"
          :placeholder="
            lang.surveyIntegrations.channels.whatsapp.settings.audience.form
              .expirationPlaceholder[languageSelected]
          "
          :show-second="false"
          :editable="false"
          :disabled-date="beforeTomorrow"
          :format="dateFormat"
          :lang="languageSelected"
          :key="languageSelected"
        />
      </div>

      <div class="mt-4" v-if="expiration">
        <vs-input
                class="inputx w-full mt-2 mb-base"
                :label="
          lang.surveyIntegrations.channels.whatsapp.settings.audience.form
              .expirationMessage[languageSelected]
        "
                v-model="expirationMessage"
        />
      </div>

      <vs-divider />

      <vs-input
        class="inputx w-full mt-2 mb-base"
        :label="
          lang.surveyIntegrations.channels[type].settings.audience.form
            .addNumber.label[languageSelected]
        "
        :placeholder="
          lang.surveyIntegrations.channels[type].settings.audience.form
            .addNumber.placeholder[languageSelected]
        "
        v-model="newAudience"
        @keypress.enter="addAudience"
      />
      <div
        class="drag-file"
        @drop="handleDrop"
        @dragover="handleDragover"
        @dragenter="handleDragover"
      >
        <feather-icon icon="UploadCloudIcon" class="w-8 h-8 float-left mb-2" />
        {{
          lang.surveyIntegrations.channels[type].settings.audience.form
            .dragAndDrop[languageSelected]
        }}
      </div>

      <div class="vx-row">
        <vs-col>
          <vs-button
            class="mb-2 mt-4 float-right"
            icon-pack="feather"
            icon="icon-download"
            type="flat"
            color="primary"
            @click="downloadExampleSchedule"
          >
            {{
              lang.surveyIntegrations.channels[type].settings.audience.form
                .buttons.downloadSchedule[languageSelected]
            }}
          </vs-button>

          <vs-button
            class="mb-2 mt-4 float-right"
            icon-pack="feather"
            icon="icon-download"
            type="flat"
            color="primary"
            @click="downloadExample"
          >
            {{
              lang.surveyIntegrations.channels[type].settings.audience.form
                .buttons.download[languageSelected]
            }}
          </vs-button>
        </vs-col>
      </div>
    </vx-card>
    <InvalidEmailsPopup
      ref="invalidEmailsPopup"
      :load="loadEmailsAgain"
      :ignore="ignoreEmails"
      v-model="badEmails"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import xlsx from '../../xlsx'
import Helper from '@/store/Helper'
import Validations from '@/store/Validations'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/es'
import InvalidEmailsPopup from '@/components/survey/InvalidEmailsPopup.vue'
import { uniqBy } from 'lodash'

export default {
  name: 'Audience',
  props: {
    type: {
      type: String,
      default: 'whatsapp',
      validator: value => ['whatsapp', 'email'].indexOf(value) !== -1
    },
    value: Array
  },
  data() {
    return {
      resultHeaders: [],
      badEmails: [],
      audience: [],
      audienceKeys: [],
      newAudience: null,
      timezone: null,
      expiration: null,
      expirationMessage: null,
      xlsAudiencesPending: []
    }
  },
  watch: {
    timezone() {
      this.$emit('timezoneChange', this.timezone.text)
    },
    expiration() {
      this.$emit('expirationChange', this.expiration)
    },
    expirationMessage() {
      this.$emit('expirationMessageChange', this.expirationMessage)
    }
  },
  components: {
    InvalidEmailsPopup,
    TimeZoneSelector: () => import('@/components/TimeZoneSelector.vue'),
    DatePicker: () => import('vue2-datepicker')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    dateFormat() {
      if (this.languageSelected === 'es') {
        return 'DD/MM/YYYY HH:mm'
      }

      return 'YYYY/MM/DD HH:mm'
    },
    getDefaultExpirationMessage(){
      return this.lang.surveyIntegrations.channels.whatsapp.settings.audience.form
              .expirationDefaultMessage[this.languageSelected]
    }
  },
  methods: {
    loadEmailsAgain() {
      this.badEmails = []
    },
    ignoreEmails() {
      this.audience = this.audience.concat(this.xlsAudiencesPending)
      this.setAudienceKeys()
      this.badEmails = []
      /// Update Audience
      this.updateAudience()
    },
    addAudience() {
      this.resultHeaders = []
      if (this.newAudience) this.newAudience = this.newAudience.trim()
      const aux =
        this.type === 'whatsapp'
          ? this.newAudience.replace(/^\D+/g, '')
          : this.newAudience

      if (aux && aux.length > 0) {
        const key = this.type === 'whatsapp' ? 'user' : 'email'
        if (key === 'email') {
          /** @type {Boolean} Is Email Valid? */
          const isEmail =
            !Validations.isEmpty(aux) && Validations.isValidEmail(aux)
          if (!isEmail) {
            this.$notify.error(
              this.lang.botMaker.addBot.notifications.couldNotSaveBot.title[
                this.languageSelected
              ],
              this.lang.surveyIntegrations.channels[this.type].settings.audience
                .form.errorEmail[this.languageSelected]
            )
            return
          }
          this.newAudience = ''
        }

        const isAlreadyInAudience =
          this.audience &&
          this.audience.find(el => {
            return el[key] === aux && Object.keys(el).length === 1
          }) !== undefined

        if (!isAlreadyInAudience) {
          this.audience.push({ [key]: aux })
          this.audienceKeys.indexOf(`${key}`) === -1 &&
            this.audienceKeys.push(`${key}`)
          this.newAudience = ''
        } else {
          let errorText =
            this.type === 'email'
              ? this.lang.surveyIntegrations.channels[this.type].settings
                  .audience.form.emailExists[this.languageSelected]
              : this.lang.surveyIntegrations.channels[this.type].settings
                  .audience.form.numberExists[this.languageSelected]
          this.$notify.error(
            this.lang.botMaker.addBot.notifications.couldNotSaveBot.title[
              this.languageSelected
            ],
            errorText
          )
          return
        }
      }

      this.$emit('input', this.audience)
    },
    removeAudience(index) {
      this.audience.splice(index, 1)
      if (!this.audience.length) {
        this.audienceKeys = []
      }
      this.$emit('input', this.audience)
    },
    removeAll() {
      this.audience = []
      this.audienceKeys = []
      this.$emit('input', this.audience)
    },
    downloadExample() {
      const isAppUrl = window.location.href.includes('/app/')
      const appUrl = isAppUrl ? '/app' : ''
      window.open(`${appUrl}/survey_template_${this.type}.xlsx`, '_blank')
    },
    downloadExampleSchedule() {
      const isAppUrl = window.location.href.includes('/app/')
      const appUrl = isAppUrl ? '/app' : ''
      window.open(
        `${appUrl}/survey_template_${this.type}_schedule.xlsx`,
        '_blank'
      )
    },
    parseNumbers(result) {
      const _createdAt = new Date()
      const badNumbers = []
      const isNumber = /^\d+$/

      const xlsAudiences = result.tickets
        .filter(element => element.user)
        .map(element => {
          if (!isNumber.test(element.user)) {
            badNumbers.push(element.user)
            return {}
          }
          let replacedElems = {}
          Object.keys(element).forEach(key => {
            // const newKey = Helper.replaceSpecialCharacters(key)
            replacedElems[key] = element[key]
          })

          return {
            ...replacedElems,
            _createdAt
          }
        })

      this.setAudienceKeys()
      // ? Object.keys(xlsAudiences[0]).filter(key => key !== '_createdAt')
      // : []

      this.audience = this.audience.concat(xlsAudiences)

      if (badNumbers.length > 0) {
        this.$vs.notify({
          title: this.lang.surveyIntegrations.channels.whatsapp.messages.error
            .invalidEmail.title[this.languageSelected],
          text: `${
            this.lang.surveyIntegrations.channels.whatsapp.messages.error
              .invalidEmail.list[this.languageSelected]
          }; ${badNumbers.slice(0, 3).join(', ')} ${
            this.lang.surveyIntegrations.channels.whatsapp.messages.error
              .invalidEmail.and[this.languageSelected]
          } ${Math.max(badNumbers.length - 3, 0)} ${
            this.lang.surveyIntegrations.channels.whatsapp.messages.error
              .invalidEmail.more[this.languageSelected]
          }`,
          color: 'warning',
          position: 'top-right'
        })
      }
    },
    validateDate(date, badDateValues, badDaysAfter) {
      const isValidDate = Validations.isValidDate(date)
      if (!isValidDate) {
        badDateValues.push(date)
      } else {
        const isDateAfterNow = Validations.isDateAfterNow(date)
        if (!isDateAfterNow) {
          badDaysAfter.push(date)
        }
        return isDateAfterNow
      }
      return isValidDate
    },
    validateScheduleExpiration(schedule, expiration, badValues) {
      const isValid = Validations.isDateValidExpiration(schedule, expiration)
      if (!isValid) {
        badValues.push(expiration)
      }
      return isValid
    },
    errorNotification(badValues, type) {
      this.$vs.notify({
        title: this.lang.surveyIntegrations.channels.whatsapp.messages.error[
          type
        ].title[this.languageSelected],
        text: `${
          this.lang.surveyIntegrations.channels.whatsapp.messages.error[type]
            .text.list[this.languageSelected]
        }; ${badValues.slice(0, 3).join(', ')} ${
          this.lang.surveyIntegrations.channels.whatsapp.messages.error[type]
            .text.and[this.languageSelected]
        } ${Math.max(badValues.length - 3, 0)} ${
          this.lang.surveyIntegrations.channels.whatsapp.messages.error[type]
            .text.more[this.languageSelected]
        }`,
        color: 'warning',
        position: 'top-right'
      })
    },
    areValidScheduleAndExpiration(result) {
      const { headers } = result
      let badSchedule = []
      let badExpiration = []
      let badScheduleExpiration = []
      let badDaysAfter = []
      if (
        headers &&
        headers.includes('expiration') &&
        headers.includes('schedule')
      ) {
        result.tickets.map(element => {
          let validExpiration
          let validSchedule
          if (element.schedule) {
            validExpiration = this.validateDate(
              element.schedule,
              badSchedule,
              badDaysAfter
            )
          }
          if (element.expiration) {
            validSchedule = this.validateDate(
              element.expiration,
              badExpiration,
              badDaysAfter
            )
          }
          if (validExpiration && validSchedule) {
            this.validateScheduleExpiration(
              element.schedule,
              element.expiration,
              badScheduleExpiration
            )
          }
        })
        if (badSchedule.length > 0) {
          this.errorNotification(badSchedule, 'invalidDatesSchedule')
        } else if (badExpiration.length > 0) {
          this.errorNotification(badExpiration, 'invalidDatesExpiration')
        } else if (badDaysAfter.length > 0) {
          this.errorNotification(badDaysAfter, 'invalidDateAfter')
        } else if (badScheduleExpiration.length > 0) {
          this.errorNotification(
            badScheduleExpiration,
            'invalidScheduleExpiration'
          )
        }
      }
      return !(
        badSchedule.length ||
        badExpiration.length ||
        badScheduleExpiration.length ||
        badDaysAfter.length
      )
    },
    setAudienceKeys() {
      /** No subir keys incorrectas */
      const extraValidation =
        (this.type == 'email' && this.resultHeaders.includes('email')) ||
        (this.type === 'whatsapp' && this.resultHeaders.includes('user'))
      if (!extraValidation) return

      const xlsAudiences = this.audience
      const audienceKeys =
        xlsAudiences.length > 0
          ? Object.keys(xlsAudiences[0]).filter(key => key !== '_createdAt')
          : []
      if (
        this.resultHeaders.length &&
        audienceKeys.length < this.resultHeaders.length
      ) {
        this.audienceKeys = [...this.resultHeaders]
      } else {
        this.audienceKeys = audienceKeys
      }
    },
    updateAudience() {
      this.setAudienceKeys()
      /// Update Audience
      this.$emit('input', this.audience)
    },
    parseEmails(result) {
      const _createdAt = new Date()
      const badEmails = []

      /** @type {Array.<{email: string, _createdAt: Date}>} */
      let xlsAudiences = result.tickets
        .filter(element => {
          /** Filter only valid email */
          if (!Validations.isValidEmail(element.email)) {
            if (element && element.email) badEmails.push(element)
            return false
          }
          return true
        })
        .map(element => {
          let replacedElems = {}
          Object.keys(element).forEach(key => {
            const newKey = Helper.replaceSpecialCharacters(key)
            replacedElems[newKey] = element[key]
          })

          return {
            ...replacedElems,
            _createdAt
          }
        })
        .filter(element => {
          if (this.audience && this.audience.length) {
            return (
              (this.audience.find(elAudience => {
                return (
                  elAudience.email.trim() === element.email.trim() &&
                  elAudience.schedule === element.email.schedule
                )
              }) === undefined)
            )
          }
          return true
        })

      xlsAudiences = uniqBy(xlsAudiences, function(elem) {
        return [elem.email, elem.schedule].join()
      })

      xlsAudiences = xlsAudiences.filter(el => {
        return (
          this.audience.find(elA => {
            return el.email === elA.email
          }) === undefined
        )
      })
      this.xlsAudiencesPending = xlsAudiences
      //this.audience = this.audience.concat(xlsAudiences)

      if (badEmails.length > 0) {
        this.badEmails = [...badEmails]
        this.$refs.invalidEmailsPopup.listenClickOutside()
      } else {
        this.audience = this.audience.concat(this.xlsAudiencesPending)
        this.xlsAudiencesPending = []
        this.updateAudience()
      }
    },
    async handleDrop(e) {
      try {
        const result = await xlsx.handleDrop(e)

        if (result.headers && result.headers.length) {
          this.resultHeaders = result.headers
        } else {
          this.resultHeaders = []
        }

        if (!result || !result.tickets) {
          this.$vs.notify({
            title: this.lang.surveyIntegrations.channels.whatsapp.messages.error
              .dragAndDrop.title[this.languageSelected],
            text: this.lang.surveyIntegrations.channels.whatsapp.messages.error
              .dragAndDrop.text[this.languageSelected],
            color: 'danger',
            position: 'top-right'
          })
          return
        }

        const areValidDates = this.areValidScheduleAndExpiration(result)
        if (!areValidDates) return

        this.xlsAudiencesPending = []
        if (this.type === 'whatsapp') {
          this.parseNumbers(result)
        } else {
          this.parseEmails(result)
        }

        this.$emit('input', this.audience)

        if (
          this.audience.length === 0 &&
          this.xlsAudiencesPending.length === 0
        ) {
          this.audienceKeys = []
          this.$vs.notify({
            title: this.lang.surveyIntegrations.channels.whatsapp.messages.error
              .missingAudience.title[this.languageSelected],
            text: this.lang.surveyIntegrations.channels.whatsapp.messages.error
              .missingAudience.text[this.languageSelected],
            color: 'warning',
            position: 'top-right'
          })
        }
      } catch (error) {
        console.error(error)
        // TODO: handle error
        this.$vs.notify({
          title: 'Oops!',
          text: 'Parece que ocurrió un error',
          color: 'danger',
          position: 'top-right'
        })
      }
    },
    handleDragover: xlsx.handleDragover,
    beforeTomorrow(date) {
      return date < new Date(new Date().setHours(24, 0, 0, 0))
    }
  },
  mounted() {
    this.timezone = Helper.getBrowserTimeZone()
    this.expirationMessage = this.getDefaultExpirationMessage
  }
}
</script>

<style lang="scss">
#audience {
  .drag-file {
    border: dashed 3px #dedede;
    border-radius: 10px;
    // background-image: linear-gradient(to right, #cecccc, transparent);
    opacity: 0.5;
    -webkit-transition: opacity 200ms linear;
    -moz-transition: opacity 200ms linear;
    -o-transition: opacity 200ms linear;
    -ms-transition: opacity 200ms linear;
    transition: opacity 200ms linear;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
  }

  .drag-file:hover {
    // background-image: linear-gradient(to right, #ff9f38, transparent);
    border-color: rgba(var(--vs-primary), 0.6);
    color: rgba(var(--vs-primary), 1);
    cursor: pointer;
    opacity: 1;
    -webkit-transition: opacity 200ms linear;
    -moz-transition: opacity 200ms linear;
    -o-transition: opacity 200ms linear;
    -ms-transition: opacity 200ms linear;
    transition: opacity 200ms linear;
  }

  .remove-all {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: #626262;

    &:hover {
      color: rgba(var(--vs-primary), 1) !important;
    }
  }
}
</style>
