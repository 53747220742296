<template>
  <vs-popup
    :button-close-hidden="true"
    persistent
    classContent="popup-example"
    :title="
      lang.surveyIntegrations.channels.email.settings.audience.form.errorEmails[
        languageSelected
      ]
    "
    :active="popup"
  >
    <div>
      <vs-table
        class="mt-0"
        max-items="10"
        :data="value"
        pagination
        search
        :no-data-text="lang.general.noDataText[languageSelected]"
      >
        <template slot="thead">
          <vs-th sort-key="email">
            email
          </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indexTr" v-for="(tr, indexTr) in data">
            <vs-td :data="data[indexTr].email">
              {{ data[indexTr].email }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="actions mt-2">
        <vs-button class="float-right mb-3" @click="stopListening(true)">
          <!-- icon-pack="feather"
        icon="icon-save" -->
          {{
            lang.surveyIntegrations.channels.email.settings.audience.form
              .loadTemplateAgain[languageSelected]
          }}
        </vs-button>
        <vs-button
          type="flat"
          class="float-right mr-4 mb-3"
          color="danger"
          @click="stopListening(false)"
        >
          <!-- icon-pack="feather"
        icon="icon-x" -->
          {{
            lang.surveyIntegrations.channels.email.settings.audience.form
              .ignoreInvalid[languageSelected]
          }}
        </vs-button>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: Array,
      default: function() {
        return []
      }
    },
    load: {
      type: Function,
      required: true
    },
    ignore: {
      type: Function,
      required: true
    }
  },
  methods: {
    functionListener(event) {
      if (event.target.matches('.vs-popup--background')) {
        event.preventDefault()
        event.stopPropagation()
        return
      }
    },
    listenClickOutside() {
      document.addEventListener('click', this.functionListener, false)
    },
    stopListening(loadBoolean) {
      document.removeEventListener('click', this.functionListener, false)
      if (loadBoolean) {
        this.load()
      } else {
        this.ignore()
      }
      this.$emit('input', [])
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    popup() {
      const toReturn = this.value && this.value.length > 0
      return toReturn
    }
  }
}
</script>
