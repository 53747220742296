var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"audience"}},[_c('vx-card',{staticClass:"overflow-hidden mb-2",attrs:{"title":_vm.lang.surveyIntegrations.channels[_vm.type].settings.audience.title[
        _vm.languageSelected
      ],"subtitle":_vm.lang.surveyIntegrations.channels[_vm.type].settings.audience.subtitle[
        _vm.languageSelected
      ]}},[_c('vs-table',{staticClass:"mt-0",attrs:{"max-items":"10","data":_vm.audience,"pagination":"","search":"","no-data-text":_vm.lang.general.noDataText[_vm.languageSelected]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_vm._l((_vm.audienceKeys),function(akey,aKey){return _c('vs-td',{key:aKey,attrs:{"data":data[indextr][akey]}},[_vm._v(_vm._s(data[indextr][akey]))])}),_vm._v(" "),_c('vs-td',[_c('feather-icon',{staticClass:"cursor-pointer float-right text-primary",attrs:{"icon":"XIcon","svgClasses":"h-3 w-3 mt-2 mr-2"},on:{"click":function($event){_vm.removeAudience(indextr)}}})],1)],2)})}}])},[_c('template',{slot:"thead"},[_vm._l((_vm.audienceKeys),function(key,aKey){return _c('vs-th',{key:aKey,attrs:{"sort-key":key}},[_vm._v("\n          "+_vm._s(key)+"\n        ")])}),_vm._v(" "),(_vm.audienceKeys.length)?_c('vs-th',[_c('a',{staticClass:"cursor-pointer remove-all",on:{"click":_vm.removeAll}},[_c('feather-icon',{attrs:{"icon":"XIcon","svgClasses":"h-3 w-3 mr-2"}}),_vm._v(" "),_c('span',[_vm._v("\n              "+_vm._s(_vm.lang.surveyIntegrations.channels[_vm.type].settings.audience
                  .removeAll[_vm.languageSelected])+"\n            ")])],1)]):_vm._e()],2)],2),_vm._v(" "),_c('TimeZoneSelector',{staticClass:"mt-4",attrs:{"showLabel":true},model:{value:(_vm.timezone),callback:function ($$v) {_vm.timezone=$$v},expression:"timezone"}}),_vm._v(" "),_c('div',{staticClass:"mt-4"},[_c('label',{staticClass:"vs-select--label",attrs:{"for":"expiration"}},[_vm._v("\n        "+_vm._s(_vm.lang.surveyIntegrations.channels.whatsapp.settings.audience.form
            .expiration[_vm.languageSelected])+"\n      ")]),_vm._v(" "),_c('date-picker',{key:_vm.languageSelected,staticClass:"inputx w-full",attrs:{"id":"expiration","type":"datetime","placeholder":_vm.lang.surveyIntegrations.channels.whatsapp.settings.audience.form
            .expirationPlaceholder[_vm.languageSelected],"show-second":false,"editable":false,"disabled-date":_vm.beforeTomorrow,"format":_vm.dateFormat,"lang":_vm.languageSelected},model:{value:(_vm.expiration),callback:function ($$v) {_vm.expiration=$$v},expression:"expiration"}})],1),_vm._v(" "),(_vm.expiration)?_c('div',{staticClass:"mt-4"},[_c('vs-input',{staticClass:"inputx w-full mt-2 mb-base",attrs:{"label":_vm.lang.surveyIntegrations.channels.whatsapp.settings.audience.form
            .expirationMessage[_vm.languageSelected]},model:{value:(_vm.expirationMessage),callback:function ($$v) {_vm.expirationMessage=$$v},expression:"expirationMessage"}})],1):_vm._e(),_vm._v(" "),_c('vs-divider'),_vm._v(" "),_c('vs-input',{staticClass:"inputx w-full mt-2 mb-base",attrs:{"label":_vm.lang.surveyIntegrations.channels[_vm.type].settings.audience.form
          .addNumber.label[_vm.languageSelected],"placeholder":_vm.lang.surveyIntegrations.channels[_vm.type].settings.audience.form
          .addNumber.placeholder[_vm.languageSelected]},on:{"keypress":function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addAudience($event)}},model:{value:(_vm.newAudience),callback:function ($$v) {_vm.newAudience=$$v},expression:"newAudience"}}),_vm._v(" "),_c('div',{staticClass:"drag-file",on:{"drop":_vm.handleDrop,"dragover":_vm.handleDragover,"dragenter":_vm.handleDragover}},[_c('feather-icon',{staticClass:"w-8 h-8 float-left mb-2",attrs:{"icon":"UploadCloudIcon"}}),_vm._v("\n      "+_vm._s(_vm.lang.surveyIntegrations.channels[_vm.type].settings.audience.form
          .dragAndDrop[_vm.languageSelected])+"\n    ")],1),_vm._v(" "),_c('div',{staticClass:"vx-row"},[_c('vs-col',[_c('vs-button',{staticClass:"mb-2 mt-4 float-right",attrs:{"icon-pack":"feather","icon":"icon-download","type":"flat","color":"primary"},on:{"click":_vm.downloadExampleSchedule}},[_vm._v("\n          "+_vm._s(_vm.lang.surveyIntegrations.channels[_vm.type].settings.audience.form
              .buttons.downloadSchedule[_vm.languageSelected])+"\n        ")]),_vm._v(" "),_c('vs-button',{staticClass:"mb-2 mt-4 float-right",attrs:{"icon-pack":"feather","icon":"icon-download","type":"flat","color":"primary"},on:{"click":_vm.downloadExample}},[_vm._v("\n          "+_vm._s(_vm.lang.surveyIntegrations.channels[_vm.type].settings.audience.form
              .buttons.download[_vm.languageSelected])+"\n        ")])],1)],1)],1),_vm._v(" "),_c('InvalidEmailsPopup',{ref:"invalidEmailsPopup",attrs:{"load":_vm.loadEmailsAgain,"ignore":_vm.ignoreEmails},model:{value:(_vm.badEmails),callback:function ($$v) {_vm.badEmails=$$v},expression:"badEmails"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }